import React from 'react';

import classNames from 'classnames';

import {
  MotorNativeAdvertisement as DefaultNativeAdvertisement,
  RealestateNativeAdvertisement
} from '@schibsted-nmp/search-frontend-components/react/advertising';
import { i18n } from '@lingui/core';
import { Label } from '@client/xandr/components/native/parts/Label';
import { RecommendationNativeAd } from '@client/xandr/components/native/RecommendationNativeAd';

import { Props } from './NativeAdComponent';
import NativeLogo from './parts/NativeLogo';
import Comment from './parts/Comment';
import { escapeString, getDescription } from './native.helper';

export function NativeAdSwitch(props: Props) {
  const { adObj, layoutType, isRecircAd, vertical } = props;
  const { native } = adObj;
  const layout = (layoutType ?? 'grid') as any;
  const isListLayout = layout === 'list';

  const ad = {
    id: `native-${adObj.targetId}`,
    heading: native.title,
    organisation_name: getDescription(native),
    image: { ...native.image, path: native.image.url },
    clickUrl: native.clickUrl
  };

  const adLabel = i18n.t({
    id: 'native.advertisement',
    message: 'advertisement'
  });

  if (isRecircAd) {
    return <RecommendationNativeAd {...props} />;
  }
  switch (vertical) {
    case 'realestate': {
      return (
        <RealestateNativeAdvertisement
          ad={ad as any}
          url={native.clickUrl}
          className="f-grid w-full h-full"
          label={({ classes }) => <Label classes={classes} adLabel={adLabel} />}
          sponsorName={() => (
            <div className="flex gap-16 mb-12">
              <NativeLogo native={native} constrainWidth={isListLayout} />
              <div className="flex items-center">
                {escapeString(native.sponsoredBy ? native.sponsoredBy : '')}
              </div>
            </div>
          )}
          contentBottom={() => (
            <div className="mt-8">
              <span>{getDescription(native)}</span>
              <Comment
                placement={`RealestateNativeAdvertisement - ${vertical}`}
                adObj={adObj}
              />
            </div>
          )}
        />
      );
    }

    // Use this for default because it is covering a bunch of different verticals
    case 'recommerce':
    case 'bap':
    case 'mc':
    case 'boat':
    case 'motor':
    case 'b2b':
    case 'car':
    case 'travel':
    default: {
      return (
        <DefaultNativeAdvertisement
          key={layout}
          ad={ad as any}
          className={classNames('f-grid w-full h-full', {
            'shadow hover:ring focus:ring outline-none': vertical === 'travel',
            'f-grid shadow-none':
              vertical === 'bap' || vertical === 'recommerce'
          })}
          layout={layout}
          url={native.clickUrl}
          label={({ classes, className }) => (
            <Label
              className={classNames(className, {
                'rounded-tl-8 rounded-br-4': vertical === 'travel'
              })}
              classes={classes}
              adLabel={adLabel}
            />
          )}
          sponsorName={() => (
            <span>
              {escapeString(native.sponsoredBy ? native.sponsoredBy : '')}
            </span>
          )}
          contentBottom={() => (
            <>
              <div className="mt-auto">
                <span>{getDescription(native)}</span>
                <div className="flex justify-end w-full">
                  <NativeLogo
                    native={native}
                    constrainWidth={isListLayout}
                    className="col-span-2"
                  />
                </div>
                <Comment
                  placement={`MotorNativeAdvertisement - ${vertical}`}
                  adObj={adObj}
                />
              </div>
            </>
          )}
        />
      );
    }
  }
}
